import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Button from "@material-ui/core/Button";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import API from "../api/axios";
import Zoom from "@material-ui/core/Zoom";
import regionesComunas from "../data-utils/regiones-comunas";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactDOM from "react-dom";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Loading from "../components/common/Loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fondoImagenUrl = require("../images/productos_apple.png");
const iconoCentral = require("../images/icono_central.png");
const logoMercadopago = require("../images/mercadopago.svg");
const pdfCondicionesGarantiaExtendida = require("../pdfs/chubb_garnetek_seguro_telefonos_moviles.pdf");
const pdfCondicionesRobo = require("../pdfs/CP MAC Seguro de Robo Equipos.pdf");

var _ = require("lodash");

const styles = (theme) => ({
  fondoImagen: {
    background: `url(${fondoImagenUrl}) no-repeat`,
    backgroundSize: "cover",
    height: "calc(100vh - 126px)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      display: "block",
      position: "fixed",
      top: 0,
      width: "100%",
      backgroundPosition: "center",
    },
  },
  iconoCentral: {
    width: "200px",
    marginLeft: "-100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tituloPrincipal: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "42px",
    lineHeight: "53px",
    color: "#0087C2",
    marginBottom: "3rem",
  },
  labelSelect: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "118.3%",
    color: "#2D2D2D",
    marginBottom: "1rem",
  },
  tituloPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#298DC0",
  },
  textoPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  textoPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#474747",
    marginBottom: "1.5rem",
  },
  textoPlan2Detalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    color: "#2D2D2D",
    marginBottom: "1.5rem",
  },
  precioPlan: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "26px",
    color: "#298DC0",
    marginBottom: "1rem",
  },
  precioPlanRef: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#298DC0",
    marginBottom: "1rem",
  },
  precioPlanDetalle: {
    fontFamily: "Source Sans Pro",
    fontStyle: "bold",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "26px",
    color: "#298DC0",
    marginBottom: "1rem",
  },
  tarjetaPlan: {
    marginBottom: "1rem",
    padding: "20px 26px",
    background: "#FFFFFF",
    border: "2px dashed #298DC0",
    boxSizing: "border-box",
    borderRadius: "5px",
    "&:lastChild": { border: "2px solid #298DC0" },
    "&:hover": {
      border: "2px solid #298DC0",
      background: "#298DC0",
      cursor: "pointer",
    },
    "&:hover > p": { color: "#FFFFFF !important" },
    width: "100%",
  },
  containerTab: {
    padding: "10px 7rem 10px 5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 1rem 10px 1rem",
    },
  },
  columnaIzquierda: {
    height: "calc(100vh - 126px)",
    overflow: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "initial",
      overflow: "initial",
      overflowX: "initial",
      backgroundColor: "rgba(255,255,255,0.8)",
      position: "relative",
      zIndex: "1",
      minHeight: "calc(100vh - 160px)",
    },
  },
  containerGrid: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
});
const queryString = require("query-string");
class Landing1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: false,
      loading: false,
      precio1: "",
      imei: "",
      producto: "",
      precio: "",
      productosList: [],
      preciosList: [],
      planes: [],
      activeTab: 1,
      planesEncontrados: [],
      planSeleccionado: undefined,
      regionesList: [],
      comunasList: [],
      testField: "",
      "N° Guia de despacho": "",
      "N° de pedido": "",
      RUT: "",
      Nombres: "",
      "Apellido Paterno": "",
      "Apellido Materno": "",
      "Fecha de Nacimiento": "",
      Dirección: "",
      Región: "",
      Comuna: "",
      Teléfono: "",
      Email: "",
      condicion1: false,
      condicion3: false,
      condicion4: false,
      isFormValid: false,
      openSnackbar: false,
      mensajesError: [],
      showBotonPagarMercadopago: false,
      campos_formulario_venta: [],
      labelWidth: 0,
      open: false,
      setOpen: false,
      user_param: null,
    };
  }
  componentDidMount() {
    /*
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
    */
   var urlhash = window.location.hash;
    console.log(window.location.hash);
    urlhash = urlhash.replace('/?','');
    console.log(urlhash);
    var values = queryString.parse(urlhash);
    console.log(values);
    
    if (values.user) {
      this.setState({
        user_param: values.user,
      });
    }
    this.setState({
      regionesList: regionesComunas.regiones.map((e) => e.region),
    });
  }
  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }
  handleChangeProducto(event) {
    this.setState({
      producto: event.target.value,
      precio: "",
      preciosList: [],
    });
    const preciosSet = new Set();
    this.state.planes
      .filter(
        (e) =>
          e.materia.descripcion == event.target.value &&
          e.name.includes("desde") &&
          e.name.includes("hasta")
      )
      .map((e) => e.name.slice(e.name.indexOf("desde")))
      .forEach((e) => {
        preciosSet.add(e);
      });
    const preciosArray = [];
    preciosSet.forEach((e) => {
      preciosArray.push(e);
    });
    this.setState({ preciosList: preciosArray });
  }
  handleChangePrecio(event) {
    console.log({ precio: event.target.value });
    this.setState({ precio: event.target.value });
  }
  toFirstTab() {
    console.log("toFirstTab");
    this.setState({ activeTab: 1 });
  }
  toSecondTab() {
    this.setState({ loading: true });

    console.log("toSecondTab");
    if (this.state.imei) {
      API({
        url: "api/landing/mtek/plans/by_imei",
        method: "POST",
        data: { imei: this.state.imei },
      })
        .then((response) => {
          /*
        this.setState({ planes: response.data });
        const productosSet = new Set();
        response.data.forEach((e) => {
          productosSet.add(e.materia.descripcion);
        });
        const productosArray = [];
        productosSet.forEach((e) => {
          productosArray.push(e);
        });
        this.setState({ productosList: productosArray });
        */
       console.log(response.data);
       this.setState({ loading: false });
          if (!response.data) {
            
            this.setState({ loading: false });
            const mensajesError = [
              {
                active: true,
                message: "No encontramos resultados. Intenta con otro IMEI.",
              },
            ];
            this.setState({ mensajesError });
          } else {

            this.setState({ loading: false });
            this.setState(
              {
                planesEncontrados: response.data.campaigns.map((e) => ({
                  ...e.plan,
                  price: (response.data.price),
                  pricePesos: (response.data.pricePesos),
                  dateUf: (response.data.dateUf),
                  valueUf: (response.data.valueUf),
                  id: response.data.id,
                })),
              },
              () => {
                if (this.state.planesEncontrados.length) {
                  this.setState({ activeTab: 2 });
                } else {
                  const mensajesError = [
                    {
                      active: true,
                      message:
                        "No encontramos resultados. Intenta con otros valores.",
                    },
                  ];
                  this.setState({ mensajesError });
                }
              }
            );
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
          console.log(e);
          const mensajesError = [
            {
              active: true,
              message: "No se han encontrado planes para este equipo",
            },
          ];
          this.setState({ mensajesError });
        });
    }
  }
  seleccionarPlan(plan) {
    this.setState({ loading: true });
    this.setState({
      planesEncontrados: this.state.planesEncontrados.map((e) => {
        e.isActive = plan.id == e.id;
        return e;
      }),
    });
    /*
    let atributosMateriaTotal = JSON.parse(plan.materia.atributos);
    console.log("atributosMateriaTotal", atributosMateriaTotal);
    let atributosMateria = [];
    // Campos provenientes de la materia
    atributosMateriaTotal.forEach((a) => {
      if (a.momento == "venta") {
        atributosMateria.push({
          name: _.capitalize(a.nombre),
          attr_type: "materia",
          data_type: "string",
        });
      }
    });
    // Campos provenientes de la subcategoria (para filtrar en el POS en vista de nueva venta)
    plan.plansubcategoria.searchablesattrs.forEach((sa) => {
      atributosMateria.push({
        name: _.capitalize(sa.public_name),
        attr_type: "subcategoria",
        data_type: sa.data_type_input,
      });
    });
    //console.log('atributosMateria', atributosMateria);
    let obj = new Object();
    atributosMateria.forEach((am) => {
      if (am.name.toLowerCase() == "el valor del equipo") {
        obj[am.name] = this.state.precio1;
      } else {
        obj[am.name] = "";
      }
    });
    */
    //console.log('obj', obj);
    //this.setState({ ...obj }, () => {
    //this.setState({ campos_formulario_venta: atributosMateria });
    this.setState({ planSeleccionado: plan });
    //});
    this.myRef.current.scrollIntoView({ behavior: "smooth" });

    this.setState({ loading: false });
  }
  toThirdTab() {
    this.setState({ loading: true });
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 3 });
    }
    this.setState({ loading: false });
  }
  toForthTab() {
    this.setState({ loading: true });
    console.log("toThirdTab");
    if (this.state.planSeleccionado) {
      this.setState({ activeTab: 4 });
    }
    this.setState({ loading: false });
  }
  handleChangeTextField(event, property) {

    if (property == "imei") {
      console.log('aimeiimimim');
      const re = /^[0-9\b]+$/;
      if (!re.test(event.target.value)) {
        event.target.value = '';
      }
    }
    
    let obj = new Object();
    obj[property] = event.target.value;
    this.setState({ ...obj });
  }
  handleChangeCheckbox(event, property) {
    let obj = new Object();
    obj[property] = event.target.checked;
    this.setState({ ...obj });
  }
  handleChangeRegion(event) {
    this.setState({ Región: event.target.value, Comuna: "", comunasList: [] });
    this.setState({
      comunasList: regionesComunas.regiones.find(
        (e) => e.region == event.target.value
      ).comunas,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log("onSubmit");
    this.setState({ isLoading: true }, () => {
      this.sendForm().catch((e) => {
        console.log(e);
      });
    });
  }
  sendForm() {
    this.setState({ loading: true });
    return new Promise((resolve, eject) => {
      const formData = _.pick(this.state, [
        "RUT",
        "Nombres",
        "Apellido Paterno",
        "Apellido Materno",
        "Fecha de Nacimiento",
        "Dirección",
        "Región",
        "Comuna",
        "Teléfono",
        "Email",
        "planSeleccionado",
        "imei",
      ]);
      formData.user = this.state.user_param ? this.state.user_param : null;
      API({
        url: "api/landing/mtek/checkout",
        method: "POST",
        data: formData,
      })
        .then(async (response) => {
          //console.log(response.data);
          //alert("Se redirigirá a: " + response.data);
          setTimeout(() => {
            window.location.href = response.data;
          }, 1500);

          /*
          this.props.dispatch({
            type: "SET_PREFERENCE_ID",
            value: 'response.data.preference_id', // OJO AQUI <<<<<<<<<<<
          });
          */
          this.setState({ isLoading: false }, () => {
            resolve();
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          this.setState({ isLoading: false });
          console.log("error.response.status", error.response.status);
          if (error.response.status == 422) {
            if (error.response.data) {
              if (error.response.data.errors) {
                if (Object.keys(error.response.data.errors).length) {
                  const mensajesError = Object.keys(
                    error.response.data.errors
                  ).map((key) => {
                    return {
                      active: true,
                      message: error.response.data.errors[key][0],
                    };
                  });
                  this.setState({ mensajesError }, () => {});
                  eject(error);
                  return;
                }
              }
            }
          }
          // const mensajesError = [
          //   {
          //     active: true,
          //     message:
          //       "Ha ocurrido un error en el servidor. Reintente mas tarde.",
          //   },
          // ];
          // this.setState({ mensajesError }, () => {});
          // eject(error);
        });
    });
  }
  handleClickBotonPagar() {
    this.setState({ isLoading: true }, () => {
      this.sendForm();
    });
  }
  handleCloseSnackbar(index) {
    let mensajesError = this.state.mensajesError.map((e, i) => {
      if (i == index) {
        return { ...e, active: false };
      } else {
        return e;
      }
    });
    this.setState({ mensajesError: mensajesError });
  }
  render() {
    const { classes } = this.props;
    const formValid =
      !this.state.isLoading &&
      !!this.state["RUT"] &&
      !!this.state["Nombres"] &&
      !!this.state["Apellido Paterno"] &&
      !!this.state["Apellido Materno"] &&
      !!this.state["Fecha de Nacimiento"] &&
      !!this.state["Dirección"] &&
      !!this.state["Región"] &&
      !!this.state["Comuna"] &&
      !!this.state["Teléfono"] &&
      !!this.state["Email"] &&
      !!this.state.condicion1 &&
      !!this.state.condicion3 &&
      !!this.state.condicion4 &&
      this.state.campos_formulario_venta.every((e) => !!this.state[e.name]);
    let pdfTerminosCondiciones = "";
    if (this.state.planSeleccionado) {
      pdfTerminosCondiciones = this.state.planSeleccionado.name
        .toLowerCase()
        .includes("robo")
        ? pdfCondicionesRobo
        : pdfCondicionesGarantiaExtendida;
    } else {
      pdfTerminosCondiciones = "http://www.maconline.cl";
    }
    return (
      <>
      <Loading loading={this.state.loading} />
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle disableTypography>
            <Typography variant="subtitle1" gutterBottom>
            Para conocer el IMEI puedes digitar *#06#* en el teléfono. Aparecerá una pantalla con esta y otras informaciones sobre el equipo El IMEI (del inglés International Mobile Equipment Identity, identidad internacional de equipo móvil) es un código USSD pregrabado en los teléfonos móviles GSM. Este código identifica al aparato de forma exclusiva a nivel mundial, y es transmitido por el aparato a la red al conectarse a esta.
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Topbar />
        <Grid className={classes.containerGrid} container spacing={0}>
          <Grid
            className={classes.columnaIzquierda}
            item
            xs={12}
            sm={12}
            md={6}
          >
            {this.state.activeTab === 1 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                  <div style={{ marginBottom: "1rem", marginTop: "2rem" }}>
                    &nbsp;
                  </div>
                  <Typography className={classes.tituloPrincipal}>
                    Obtén en simples pasos el seguro para tu teléfono móvil
                  </Typography>
                  <Typography className={classes.labelSelect}>IMEI</Typography>
                  <FormControl
                    style={{
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                    }}
                    variant="outlined"
                  >
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Grid item>
                        <Tooltip title="¿Cómo lo obtengo?">
                          <IconButton
                            onClick={() => this.handleClickOpen()}
                            aria-label="¿Cómo lo obtengo?"
                          >
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid style={{ flexGrow: "1", cursor: "pointer" }}>
                        <TextField
                          style={{ display: "flex" }}
                          label="Ingrese el IMEI del equipo"
                          defaultValue={this.state["imei"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "imei")
                          }
                          variant="outlined"
                          onKeyDown={(e) =>
                            (e.key == "," || e.key == ".") && e.preventDefault()
                          }
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                  <Button
                    onClick={() => this.toSecondTab()}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                    }}
                    variant="contained"
                    disabled={!this.state.imei}
                  >
                    Continuar
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 2 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                  <Button
                    onClick={() => this.toFirstTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "2rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Volver atrás
                  </Button>
                  <Typography className={classes.tituloPrincipal}>
                    Encontramos{" "}
                    {this.state.planesEncontrados.length == 1
                      ? "un"
                      : this.state.planesEncontrados.length}{" "}
                    seguro
                    {this.state.planesEncontrados.length === 1 ? "" : "s"} para
                    tu teléfono móvil:
                  </Typography>
                  <>
                    {this.state.planesEncontrados.map((e, i) => (
                      <div
                        onClick={() => this.seleccionarPlan(e)}
                        key={i}
                        className={`${classes.tarjetaPlan} ${
                          e.isActive ? "plan-active" : ""
                        }`}
                      >
                        <Typography
                          style={{ marginBottom: "25px", display: "none" }}
                          className={classes.tituloPlan}
                        >
                          {e.name}{" "}
                          {e.premiumFrequency == "Monthly"
                            ? "Mensual"
                            : e.premiumFrequency == "Annually"
                            ? "Anual"
                            : ""}
                        </Typography>
                        <Typography className={classes.textoPlan}>
                          <h2>Coberturas:</h2>
                          {e.coverages.map((coverage) => (
                            <div>
                              <h3 style={{ marginBottom: "0" }}>{coverage.name}</h3>
                              <p style={{ marginTop: "0", marginBottom: "0" }}>{coverage.description}</p>
                              <p style={{ marginTop: "0" }}>Deducible: {coverage.deductibleValue} UF</p>
                            </div>
                          ))}
                        </Typography>
                        <Typography className={classes.precioPlan} style={{ marginBottom: "0" }} >
                          Valor: ${parseFloat(e.pricePesos).toLocaleString("es")} <span style={{ fontSize: "14px" }}>mensual</span>
                        </Typography>
                        <Typography className={classes.precioPlanRef}>
                          ({parseFloat(e.price).toLocaleString("de-DE")} UF)  
                          <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(e.valueUf).toLocaleString("es")} al {e.dateUf})</small>
                        </Typography>
                      
                        
                      </div>
                    ))}
                  </>
                  <Button
                    ref={this.myRef}
                    onClick={() => this.toThirdTab()}
                    disabled={!this.state.planSeleccionado}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                    }}
                    variant="contained"
                  >
                    Continuar
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 3 && (
              <Zoom in={true} timeout={400}>
                <div className={classes.containerTab}>
                  <Button
                    onClick={() => this.toSecondTab()}
                    style={{
                      marginBottom: "1rem",
                      marginTop: "2rem",
                      color: "#878787",
                      fontFamily: "Roboto",
                    }}
                  >
                    <ArrowBack></ArrowBack> Volver atrás
                  </Button>
                  <Typography className={classes.tituloPrincipal} style={{ display: "none"}}>
                    {this.state.planSeleccionado.name}
                  </Typography>
                  <Typography className={classes.textoPlanDetalle}>
                    <h2>Coberturas:</h2>
                    {this.state.planSeleccionado.coverages.map((coverage) => (
                      <div>
                        <h3  style={{ marginBottom: "0" }}>{coverage.name}</h3>
                        <p style={{ marginTop: "0", marginBottom: "0" }}>{coverage.description}</p>
                        <p style={{ marginTop: "0" }}>Deducible: {coverage.deductibleValue} UF</p>
                      </div>
                    ))}
                  
                    <h3 style={{ margin: 0 }} >Beneficios:</h3>
                    {this.state.planSeleccionado.benefits.length &&
                      this.state.planSeleccionado.benefits.map((benefit) => (
                        <div>
                          <p style={{ marginTop: "0" }}>Además al proteger tu equipo te entregamos el siguiente Beneficio Mcafee que te protege contra robos, mejora tu privacidad y seguridad al tener antivirus y potencia el rendimiento de tu equipo.</p>
                          {/* <h6 style={{ margin: 0 }} >{benefit.title}</h6>
                          <small style={{ margin: 0 }} >{benefit.description}</small> */}
                        </div>
                      ))}
                  </Typography>
                  <Typography style={{marginBottom: "40px", fontWeight: '600', marginTop: '-15px', fontStyle: 'italic'}}>
                  <small >Seguro cubre dos eventos al año.</small>
                  </Typography>
                  <Typography className={classes.precioPlanDetalle}>
                          Valor: ${parseFloat(this.state.planSeleccionado.pricePesos).toLocaleString("es")} <span style={{ fontSize: "18px" }}>mensual</span>
                        </Typography>
                        <Typography className={classes.precioPlanRef} style={{marginBottom: "25px"}}>
                          ({parseFloat(this.state.planSeleccionado.price).toLocaleString("de-DE")} UF)
                          <small style={{ fontSize: "12px", fontStyle: 400, marginLeft: "5px" }}>(Referencia ${parseFloat(this.state.planSeleccionado.valueUf).toLocaleString("es")} al {this.state.planSeleccionado.dateUf})</small>
                        </Typography>
                  <Button
                    onClick={() => this.toForthTab()}
                    disabled={!this.state.planSeleccionado}
                    color="primary"
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontWeight: "bold",
                      fontSize: "24px",
                      textTransform: "capitalize",
                      marginBottom: "2rem",
                      alignSelf: "stretch",
                      maxWidth: "450px",
                      padding: "1rem 0rem",
                    }}
                    variant="contained"
                  >
                    Seleccionar
                  </Button>
                </div>
              </Zoom>
            )}
            {this.state.activeTab === 4 && (
              <Zoom in={true} timeout={400}>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                  <div className={classes.containerTab}>
                    <Button
                      onClick={() => this.toThirdTab()}
                      style={{
                        marginBottom: "1rem",
                        marginTop: "2rem",
                        color: "#878787",
                        fontFamily: "Roboto",
                      }}
                    >
                      <ArrowBack></ArrowBack> Volver atrás
                    </Button>
                    <Typography className={classes.tituloPrincipal}>
                      Ya queda poco para quedar protegido. Solo faltan un par de datos más
                    </Typography>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["RUT"]}
                          onChange={(e) => this.handleChangeTextField(e, "RUT")}
                          label="RUT (sin puntos y con guión)"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Nombres"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Nombres")
                          }
                          label="Nombres"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Paterno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Paterno")
                          }
                          label="Apellido Paterno"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Apellido Materno"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Apellido Materno")
                          }
                          label="Apellido Materno"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          defaultValue={this.state["Fecha de Nacimiento"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Fecha de Nacimiento")
                          }
                          label="Fecha de Nacimiento"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Dirección"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Dirección")
                          }
                          label="Dirección"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-region-label">
                            Región
                          </InputLabel>
                          <Select
                            label="Región"
                            id="select-region"
                            value={this.state["Región"]}
                            onChange={(e) => this.handleChangeRegion(e)}
                          >
                            {this.state.regionesList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="select-comuna-label">
                            Comuna
                          </InputLabel>
                          <Select
                            label="Comuna"
                            id="select-comuna"
                            value={this.state["Comuna"]}
                            onChange={(e) =>
                              this.handleChangeTextField(e, "Comuna")
                            }
                          >
                            {this.state.comunasList.map((e) => (
                              <MenuItem key={e} value={e}>
                                {e}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Teléfono"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Teléfono")
                          }
                          label="Teléfono"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingRight: "2rem",
                          marginBottom: "1rem",
                        }}
                        item
                        xs={12}
                        sm={6}
                        md={6}
                      >
                        <TextField
                          defaultValue={this.state["Email"]}
                          onChange={(e) =>
                            this.handleChangeTextField(e, "Email")
                          }
                          label="Email"
                          type="email"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion1}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion1")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ paddingTop: "10px", marginBottom: "1rem" }}
                      >
                        <Link
                          style={{
                            textDecoration: "underline",
                            fontFamily: "Source Sans Pro",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                          href={pdfTerminosCondiciones}
                          target="_blank"
                        >
                          He leído y comprendo las condiciones generales y
                          particulares{" "}
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion3}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion3")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Autorizo a Chubb Seguros Chile S.A. para que pueda
                        efectuar el tratamiento y almacenamiento de mis datos
                        personales proporcionados en esta contratación del
                        Seguro y puedan ser utilizados en la administración,
                        análisis, cumplimiento y/o auditoría del mismo.
                      </Grid>
                    </Grid>
                    <Grid
                      style={{ flexWrap: "nowrap", paddingRight: "2rem" }}
                      container
                      spacing={0}
                    >
                      <Grid
                        item
                        style={{ marginBottom: "1rem", marginRight: "10px" }}
                      >
                        <Checkbox
                          checked={this.state.condicion4}
                          onChange={(e) =>
                            this.handleChangeCheckbox(e, "condicion4")
                          }
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          paddingTop: "10px",
                          marginBottom: "1rem",
                          color: "#878787",
                          fontFamily: "Source Sans Pro",
                          fontWeight: "400",
                          fontSize: "14px",
                        }}
                      >
                        Declaro que acepto voluntariamente la contratación de
                        este seguro.
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      disabled={!formValid}
                      color="primary"
                      style={{
                        display: "none",
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                      }}
                      variant="contained"
                    >
                      Continuar
                    </Button>
                    <Button
                      disabled={this.state.isLoading || !formValid}
                      onClick={() => this.handleClickBotonPagar()}
                      type="button"
                      color="primary"
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontWeight: "bold",
                        fontSize: "24px",
                        textTransform: "capitalize",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        alignSelf: "stretch",
                        maxWidth: "450px",
                        padding: "1rem 0rem",
                      }}
                      variant="contained"
                    >
                      Comprar
                    </Button>
                  </div>
                </form>
              </Zoom>
            )}
          </Grid>
          <Grid className={classes.fondoImagen} item xs={12} sm={12} md={6}>
            <img
              className={classes.iconoCentral}
              src={iconoCentral}
              alt="icono"
            ></img>
          </Grid>
        </Grid>
        <FooterGarnet />

        {this.state.mensajesError.map((e, i) => (
          <Snackbar
            style={{ marginBottom: String(55 * i) + "px" }}
            key={i}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={e.active}
            autoHideDuration={6000}
            variant="error"
            message={e.message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.handleCloseSnackbar(i)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        ))}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  preference_id: state.preference_id,
});
export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Landing1))
);
