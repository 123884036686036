import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Container, Link as MaterialLink } from "@material-ui/core";
import Menu from "./Menu";

const logoGarnet = require("../images/logo-maqui.png");
const logoMaconline = require("../images/Maconline seguros.png");

const styles = (theme) => ({
  appBar: {
    position: "relative",
    boxShadow: "none",
    backgroundColor: "#15669D",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexTopbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem !important",
    },
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  containerTopbar: {
    padding: "10px 5rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
      paddingRight: "0rem",
      marginLeft: "0rem",
      marginRight: "0rem",
    },
  },
  productLogo: {
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.grey["A100"]}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up("md")]: {
      paddingTop: "1.5em",
    },
  },
  tagline: {
    display: "inline-block",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      paddingTop: "0.8em",
    },
  },
  iconContainer: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  iconButton: {
    float: "right",
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: "auto",
  },
  logoGarnet: {
    [theme.breakpoints.down("sm")]: {
      height: "32px",
    },
  },
  logoMaconline: {
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
  },
});

class Topbar extends Component {
  state = {
    value: 0,
    menuDrawer: false,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  mobileMenuOpen = (event) => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = (event) => {
    this.setState({ menuDrawer: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  current = () => {
    if (this.props.currentPath === "/home") {
      return 0;
    }
    if (this.props.currentPath === "/dashboard") {
      return 1;
    }
    if (this.props.currentPath === "/signup") {
      return 2;
    }
    if (this.props.currentPath === "/wizard") {
      return 3;
    }
    if (this.props.currentPath === "/cards") {
      return 4;
    }
  };
  

  render() {
    const { classes } = this.props;
    const openInNewTab = (url) => {
      window.open(url, '_blank', 'noreferrer');
    };
    return (
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Grid
            className={classes.containerTopbar}
            container
            spacing={10}
            alignItems="baseline"
          >
            <Grid item xs={12} className={classes.flexTopbar}>
              <div className={classes.flexAlignCenter}>
                <Link   onClick={() => openInNewTab('https://maquiseguros.cl')}  className={classes.link}>
                  <img
                    className={classes.logoGarnet}
                    src={logoGarnet}
                    alt="garnet"
                  />
                </Link>
              </div>
              <div className={classes.flexAlignCenter}>
                <img
                  className={classes.logoMaconline}
                  src={logoMaconline}
                  alt="maconline seguros"
                />
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(withStyles(styles)(Topbar));
