import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
/*
global.config = {
    mercadopago: new window.MercadoPago(process.env.REACT_APP_MERCADOPAGO_ACCESS_TOKEN, {
        locale: 'es-CL'
    })
}
*/
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
