const initialState = {
    preference_id: ''
}

const appReducer = (state = initialState, action) => {
    switch(action.type){
        case "SET_PREFERENCE_ID":
            return {
                preference_id: action.value
            };
        default:
            return state;
    }
    return state;
};

export default appReducer;