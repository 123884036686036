const Months = [
    '01 December 2018',
    '01 January 2019',
    '01 February 2019',
    '01 March 2019',
    '01 April 2019',
    '01 May 2019',
    '01 June 2019',
    '01 July 2019',
    '01 August 2019',
    '01 September 2019',
    '01 October 2019',
    '01 November 2019',
    '01 December 2019',
    '01 January 2020',
    '01 March 2020',
    '01 April 2020',
    '01 May 2020',
    '01 June 2020',
    '01 July 2020',
    '01 August 2020',
    '01 September 2020',
    '01 October 2020',
    '01 November 2020',
    '01 December 2020',
    '01 January 2020',
    '01 February 2020',
    '01 March 2020',
    '01 April 2020',
  ];

  export default Months;